import { Image, TouchableOpacity, View, ViewProps, ScrollView } from "react-native";
import { withExternalStyle } from "../../utils";
import { useThemedStyleFunction } from "../theme-provider";
import { ThemedText } from "../themed-text";
import { imageRowStyle } from "./image-row.style";
import { FC } from "react";

type ImageRowProps = ViewProps & {
  images?: string[];
  videoId?: string;
  slots: number;
  onImagePress?: (index: number) => void;
  onVideoPress?: (videoId: string) => void;
  videoPlayer?: FC<{ videoId: string; onPress: (videoId: string) => void }>;
};

const ImageRow = ({
  style: styleProp,
  images = [],
  videoId,
  slots,
  onImagePress,
  onVideoPress,
  videoPlayer: VideoPlayer,
}: ImageRowProps) => {
  const totalEntries = [
    ...(videoId ? [videoId] : []),
    ...(images || []), 
  ];

  const excess = totalEntries.length - slots;
  const style = useThemedStyleFunction(imageRowStyle);
  const containerStyle = withExternalStyle(style.container, styleProp);

  return (
    <View style={containerStyle}>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        {videoId && VideoPlayer && (
          <View style={style.tile}>
            <VideoPlayer 
              videoId={videoId} 
              onPress={() => onVideoPress?.(videoId)} 
            />
          </View>
        )}
        {images.slice(0, slots).map((entry, index) => (
          <View key={`image-${index}`}>
            <TouchableOpacity
              style={style.tile}
              onPress={() => onImagePress?.(index)}
            >
              <Image style={style.image} source={{ uri: entry }} />
            </TouchableOpacity>

            {index === slots - 1 && excess > 0 && (
              <View style={style.tileOverlay}>
                <ThemedText style={style.excess}>
                  +{excess}
                </ThemedText>
              </View>
            )}
          </View>
        ))}
      </ScrollView>
    </View>
  );
};

export { ImageRow };
