import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const youtubePlayerStyle = (theme: Theme) => StyleSheet.create({
    mobileVideoContainer: {
        width: 166,
        height: 166,
        backgroundColor: "#000",
        borderRadius: 10,
        overflow: "hidden",
        alignItems: "center",
        justifyContent: "center",
    },
    mobileVideoWrapper: { 
        height: 90,
        width: 166,
    },
    webVideoWrapper: { 
        borderRadius: 10,
        overflow: 'hidden',
    },
});

export { youtubePlayerStyle };