import { StyleSheet } from "react-native";
import { Theme } from "../theme-provider";

const navigationHeaderStyle = (theme: Theme) => StyleSheet.create({
    container: {
        position: 'relative',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        height: 6 * theme.units.base,
        paddingHorizontal: theme.spacing.sides,
        zIndex: 10000
    },
    inverted: {
        color: theme.color.textInverted,
    },
    title: {
        fontSize: theme.fontSize.large,
    },
    iconContainer: {
        width: theme.iconSize.large,
        height: theme.iconSize.large,
    },
    icon: {
        flex: 1,
        color: theme.color.textPrimary
    },
    row: {
        flexDirection: 'row'
    },

    searchContainer: {
        position: 'absolute',
        left:0,
        right:0,
        backgroundColor: theme.color.background
    },

    searchContainerHasResult: {
        backgroundColor: 'rgba(0, 0, 0, 0.35)',
    },

    searchInputContainer: {
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        backgroundColor:  theme.color.background,
        margin: theme.spacing.half,
        borderRadius: theme.borderRadius.standard,
    },

    darkSearchInputContainer: {
        backgroundColor: theme.color.listBackground,
    },
  
    searchInput: {
        flex: 1,
        height: theme.units.base * 4,
        fontWeight: 'bold',
        padding: theme.spacing.half,
        fontSize: theme.fontSize.large,
        color: theme.color.textPrimary
    },
  
    searchInputIcon: {
        margin: theme.spacing.half,
        width: theme.units.base * 1.2,
        height: theme.units.base * 1.2,
    },

    searchInputIconLeft: {
        color: theme.color.graphPrimaryOverlay,
        marginRight: 0,
    },
  
    searchInputIconRight: {
       color: theme.color.mapIcons,
    },
  
    searchInputIconRightContainer: {
        marginLeft: 'auto',
    },
  
    searchResultDropDownContainer: {
        position: 'absolute',
        top: '100%',
        backgroundColor: theme.color.background,
        width: '100%',
        maxHeight: theme.units.base * 22,
        flex: 1,
        shadowColor: theme.color.black,
        shadowOffset: {
          width: 0,
          height: 22,
        },
        shadowOpacity: 0.4,
        shadowRadius: 16.0,
        elevation: 10,
        zIndex: 100000
    },
    mapIcon: {
        marginRight: theme.spacing.standard,
    }
});

export { navigationHeaderStyle };