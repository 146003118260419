import { create } from 'zustand';

interface VideoViewer {
    // Image Viewer data
    videoId: string;
    showVideo?: boolean;

    showVideoViewer: (videoId: string) => void;
    hideVideoViewer: () => void;
};

/**
 * Hook for accessing the image viewer data store
 */
const useVideoViewerStore = create<VideoViewer>((set) => ({
    videoId:'',
    showVideoViewer: (videoId: string) => {
        set({
        showVideo: true,
        videoId,
        })
    },
    hideVideoViewer: () => set({
        showVideo: false,
        videoId: undefined,
    }),
}));

export { VideoViewer, useVideoViewerStore };