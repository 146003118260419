import { useCallback, useState } from 'react';
import { TouchableOpacity, View } from 'react-native';
import { useTranslation } from "react-i18next";
import { getDistanceTo, toKilometerString } from "../../../utils";
import { useThemedStyleFunction } from "../../theme-provider";
import { ElevationGraph } from '../../elevation-graph';
import { ThemedText } from '../../themed-text';
import { Path, PathGpsCoordinate } from '../../../interfaces';
import { share } from '../../../utils';
import { Share, SmallArrowBack, SmallArrowForward } from '../../../assets';
import { getTranslatedProperty } from '../../../utils/localization.utils';
import { ImageRow } from '../../image-row';
import { detailsContentStyle } from '../details-content.style';
import { PathDirection } from '@miqmap/map-content/src/enums';
import { SocialMediaList } from '../../social-media-list';


type PathDetailsContentBottomProps = Path & {
    gpsCoordinates: PathGpsCoordinate[],
    closestIndex: number,
    pathDirection?: PathDirection,
    onImagePress?: (images: string[], index: number) => void,
    onPathDirection?: (direction: PathDirection) => void,
    onVideoPress?: (vidoeId: string) => void,
    videoPlayer?: React.FC<{ videoId: string, onPress: (videoId: string) => void }>,
};

const PathDetailsContentBottom = (props: PathDetailsContentBottomProps) => {
    const {gpsCoordinates, closestIndex, imageUrls, pathDirection, onPathDirection, onImagePress, socialMedia, onVideoPress, youtubeVideoId, videoPlayer} = props;
    const hasMedia = (imageUrls?.length ?? 0) > 0 || !!youtubeVideoId;

    const [t] = useTranslation();

    // Left and right distance fields (distance traveled and total length)
    const hasLocation = closestIndex !== -1;
    const isReversed = pathDirection === PathDirection.Reverse;
    const totalDistance = getDistanceTo(gpsCoordinates, gpsCoordinates.length - 1);
    const distanceTraveled = hasLocation
        ? getDistanceTo(gpsCoordinates, isReversed ? gpsCoordinates.length - 1 - closestIndex : closestIndex) : 0;
    const leftText = hasLocation
        ? toKilometerString(distanceTraveled) : '';
    const rightText = hasLocation
        ? toKilometerString(totalDistance - distanceTraveled) : toKilometerString(totalDistance);

    const description = getTranslatedProperty('description', props);

    const handleReverse = useCallback(() => {
        onPathDirection?.(pathDirection === PathDirection.Forward ? PathDirection.Reverse : PathDirection.Forward);
    }, [pathDirection, onPathDirection]);

    const name = getTranslatedProperty('name', props);
    const handleShare = useCallback(() => {
        const message = `(${name.toLowerCase()})`;
        const url = 'http://www.example.com';

        share(message, url);
    }, [share, name]);

    const handleOnImagePress = useCallback((index: number) => {
        onImagePress?.(imageUrls ?? [], index);
    }, [imageUrls]);

    const handleOnVideoPress = useCallback((id: string) => {
        onVideoPress?.(id);
    }, [youtubeVideoId]);
    
    const style = useThemedStyleFunction(detailsContentStyle);

    return (
        <>
            <View style={[style.paragraph, style.container]}>
            <View style={style.separatorLine} />
                <ElevationGraph gpsCoords={gpsCoordinates} reversed={isReversed} closestIndex={closestIndex}/>
                <View style={style.graphInfo}>
                    <ThemedText style={[style.standard, style.secondary, style.leftGraphText]} type='bold'>{leftText}</ThemedText>
                    <TouchableOpacity style={style.row} onPress={handleReverse}>
                        <SmallArrowBack style={style.arrowIcon}/>
                        <ThemedText style={[style.standard, style.link, style.capitalize]}>{t('switchDirection')}</ThemedText>
                        <SmallArrowForward style={style.arrowIcon}/>
                    </TouchableOpacity>
                    <ThemedText style={[style.standard, style.secondary, style.rightGraphText]}>{rightText}</ThemedText>
                </View>
            </View>

            <View style={[style.row, style.paragraph, style.container]}>
                 {/* TEMPORARY HIDDEN */}
                {/* <TouchableOpacity style={[style.row, style.share]} onPress={handleShare}>
                    <ThemedText style={[style.standard, style.link, style.capitalize]} type='bold'>{t('share')}</ThemedText>
                    <Share style={style.shareIcon}/>
                </TouchableOpacity> */}
            </View>

            { hasMedia && (
                <View style={style.imagesContainer}>
                    <ImageRow 
                        slots={4} 
                        images={imageUrls} 
                        onImagePress={handleOnImagePress}
                        videoId={youtubeVideoId} 
                        onVideoPress={handleOnVideoPress}
                        videoPlayer={videoPlayer}/>
                </View>
            )}

            { description &&
                <View style={[style.paragraph, style.container]}>
                    <ThemedText style={[style.standard, style.primary]}>{description}</ThemedText>
                </View>
            }
            {socialMedia && 
                <View style={[style.paragraph, style.container]}>
                    <SocialMediaList {...socialMedia} />
                </View>
            }
        </>
    )
};

export { PathDetailsContentBottom };