export default {
    // Status
    open: 'Öppen',
    closed: 'Stängd',
    groomedStatusCurrently: 'Prepareras',
    groomedStatusPassed: 'Preparerat ({{hoursPassed}} h)',
    groomedStatusYesterday: 'Preparerat igår',
    groomedStatusWeekday: 'Preparerat {{weekday}}',
    // Zone
    low: 'Lågzon',
    high: 'Högzon',
    // Difficulty
    veryEasy: 'Mycket lätt',
    veryEasyPlural: 'Mycket lätta',
    easy: 'Lätt',
    easyPlural: 'Lätta',
    medium: 'Medelsvår',
    mediumPlural: 'Medelsvåra',
    hard: 'Svår',
    hardPlural: 'Svåra',
    // Abbreviated weekdays
    abbreviatedMonday: 'mån',
    abbreviatedTuesday: 'tis',
    abbreviatedWednesday: 'ons',
    abbreviatedThursday: 'tor',
    abbreviatedFriday: 'fre',
    abbreviatedSaturday: 'lör',
    abbreviatedSunday: 'sön',
    // Measurements
    elevationAboveSeaLevel: 'm.ö.h.',
    // Misc
    switchDirection: 'Vänd',
    destinationNamePlaceholder: 'Titlis',
};
