import { StyleSheet } from "react-native";
import { Theme } from "../../theme-provider";

const listContentStyle = (theme: Theme) => StyleSheet.create({
    container: {
        flexDirection: 'row',
        paddingVertical: theme.spacing.standard,
        paddingHorizontal: theme.spacing.semiDouble,
        height: theme.units.base * 6.4,
        alignContent: 'center',
        shadowColor: '#000',
        shadowOffset: {
          width: 22,
          height: 0,
        },
        shadowOpacity: 0.4,
        shadowRadius: 16.0,
        elevation: 10,
    },
    content: {
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        fontSize: theme.fontSize.medium,
        color: theme.color.textPrimary,
    },
    subtitle: {
        fontSize: theme.fontSize.small,
        color: theme.color.textPrimary,
        opacity: 1,
        width: '65%',
        flexWrap: 'nowrap',
        paddingRight:  theme.spacing.half,
    },
    length: {
        fontSize: theme.fontSize.medium,
        color: theme.color.textSecondary,
    },
    status: {
        fontSize: theme.fontSize.small,
        color: theme.color.textSecondary,
    },
    difficultyIcon: {
        height: theme.iconSize.large,
        width: theme.iconSize.large,
        marginRight: theme.spacing.half,
    },
    flexRow: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    row: {
        justifyContent: 'space-between',
        marginBottom: theme.spacing.sliver,
    }
});

export { listContentStyle };