import React from 'react';
import { View } from 'react-native';
import { PoiListContentProps } from '../../../interfaces';
import { getTranslatedProperty } from '../../../utils/localization.utils';
import { getPoiCategoryIcon } from '../../../utils';

import { useThemedStyleFunction } from '../../theme-provider';
import { ThemedText } from '../../themed-text';
import { listContentStyle } from './list-content.style';

const PoiListContent = (props: PoiListContentProps) => {
    const {category} = props;
    const name = getTranslatedProperty('name', props);
    const categoryName = getTranslatedProperty('name', category);
    const CategoryIcon = (props: any) => getPoiCategoryIcon(category.id, props)

    const style = useThemedStyleFunction(listContentStyle);

    return (
        <View style={style.container}>
            <CategoryIcon style={style.categoryIcon}/>
            <View style={style.content}>
                <View style={[style.flexRow, style.row]}>
                    <ThemedText type="bold" style={style.title}>{ name }</ThemedText>
                </View>
                <View style={[style.flexRow, style.row]}>
                    <ThemedText type="standard" style={style.subtitle}>{ categoryName }</ThemedText>
                </View>
            </View>
        </View>
    );
};

export { PoiListContent };