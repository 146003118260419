import React, { useRef } from "react";
import { View } from "react-native";
import ReactPlayer, { YouTubeProps } from "react-youtube";
import { youtubePlayerStyle } from "./youtube-web-player.style";
import { useThemedStyleFunction } from "../theme-provider";

type YoutubeWebPlayerProps = YouTubeProps & {
  onPress: (videoId: string) => void;
};

const YoutubeWebPlayer = ({ videoId, onPress }: YoutubeWebPlayerProps) => {
  const style = useThemedStyleFunction(youtubePlayerStyle);
  const videoRef = useRef<any>(null);
  const opts = {
    height: 166,
    width: 166,
    zIndex: 0,
    playerVars: {
      autoplay: 0,
      controls: 0,
      rel: 0,
      showinfo: 0,
    },
  };

  const handlePlayVideo = (videoId: string = "") => {
    onPress(videoId);
    videoRef.current?.internalPlayer.stopVideo();
  };

    return (
      <View style={style.webVideoWrapper}>
        <ReactPlayer
          ref={videoRef}
          videoId={videoId}
          opts={opts}
          onPlay={() => handlePlayVideo(videoId)}
        />
      </View>
    );
};

export { YoutubeWebPlayer };
