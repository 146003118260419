export default {
    emptyResult: 'Keine Objekte entsprechen den aktuellen Filtern.',

    // Area
    area: 'Bereich',
    areaAll: 'Alle Bereiche',

    // Length
    length: 'Länge',
    lengthAll: 'Alle Längen',

    // Difficulty
    difficulty: 'Schwierigkeit',
    difficultyAll: 'Alle Schwierigkeiten',

    // Status
    status: 'Status',
    statusAll: 'Alle Status',
    statusOpen: 'Offen',
    statusClosed: 'Geschlossen',
    statusGroomedToday: 'Heute gepflegt',
    statusGroomedYesterdayOrToday: 'Gestern oder heute gepflegt',
};