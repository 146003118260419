enum PathStatus {
    Open = 'open',
    Closed = 'closed',
    NotSet = 'notSet',
};

enum FeatureType {
    Path = 'path',
    Poi = 'poi',
    Aoi = 'aoi',
    SnowmobilePath = 'snowmobile-path',
    SnowmobileSubPath = 'snowmobile-subpath',
};

enum MapType {
    Map = 'map',
    Satelite = 'satelite',
};

enum PathCategoryId {
    CrossCountrySkiing = '1',
    Snowmobiling = '2',
    MountainBiking = '3',
    Hiking = '4',
};

enum PathDirection {
    Forward = 'forward',
    Reverse = 'reverse',
};

enum PoiCategoryId {
    Restaurants = '1',
    Cafes = '2',
    Attractions = '3',
    Viewpoints = '4',
    Parkings = '5',
    Cabins = '6',
    BusStops = '7',
    Shelters = '8',
    Shops ='9',
    BicycleWashes = '10',
    RastsHouses = '11',
    ScooterRentals = '12',
    SportsRentals = '13',
    Toilets = '14',
    Vallabodars = '15',
    Warnings = '16'
};

enum AoiCategoryId {
    WarningArea = '1',
    ImportantArea = '2',
    InfoArea = '3',
};

export {
    PathStatus,
    FeatureType,
    MapType,
    PathCategoryId,
    PathDirection,
    PoiCategoryId,
    AoiCategoryId
};