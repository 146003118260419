import React from 'react';
import { View } from 'react-native';
import moment from 'moment';
import { PathCategoryId, PoiCategoryId, AoiCategoryId } from '@miqmap/shared';
import { colors } from './variables';
import { DisplayStatus, InformationSeverity, PathDifficulty, PathStatus } from '../enums';
import { 
    AoiImportant,
    AoiInfo,
    AoiWarning,
    DifficultyVeryEasy, 
    DifficultyEasy, 
    DifficultyMedium, 
    DifficultyHard, 
    PathCrossCountrySkiing, 
    PathSnowmobiling, 
    PathMountainBiking, 
    PathHiking, 
    PoiRestaurants, 
    PoiAttractions, 
    PoiViewpoints, 
    PoiParking,
    PoiBusStops,
    PoiCabins,
    PoiShelters,
    PoiStores,
    PoiToilets,
    PoiWarnings,  
    PoiCafes, 
    PoiPlaceOfInterests,
    FacebookIcon, 
    InstagramIcon, 
    LinkedInIcon, 
    TripAdvisorIcon, 
    TwitterIcon, 
    VimeoIcon, 
    YouTubeIcon, 
    InformationSeverityInfo,
    InformationSeverityWarning,
    InformationSeverityImportant
} from '../assets';
import { TFunction } from 'i18next'; 

const FRESHLY_GROOMED_CUTOFF = 1;

enum GroomedState {
    Grooming = 'grooming',
    FreshlyGroomed = 'freshlyGroomed',
    GroomedToday = 'groomedToday',
    GroomedYesterday = 'groomedYesterday',
    GroomedWithinWeek = 'groomedWithinWeek',
    GroomedPastWeek = 'groomedPastWeek',
};
  
type GroomedStateReturnType = {
    state: string;
    hoursPassed?: number;
    weekday?: number;
};

type SocialMediaIcons = {
    [key: string]: JSX.Element;
  };

const formatLength = (length: number): string => `${length.toFixed(1).replace('.', ',')} km`;

const filterDisplayed = <T,>(array: T[]): T[] => {
    const date = new Date();
    const filtered: T[] = array.filter((entry: any) => {
        return entry.display == DisplayStatus.Display || 
             ( entry.display == DisplayStatus.Scheduled &&
               entry.timePeriod &&
               new Date(entry.timePeriod?.start) <= date &&
               new Date(entry.timePeriod?.end) >= date);
    });
    return filtered;
};

const getDifficultyIcon = (difficulty: string, props?: any) => {
    switch (difficulty) {
        case PathDifficulty.VeryEasy:
            return <DifficultyVeryEasy {...props} />;

        case PathDifficulty.Easy:
            return <DifficultyEasy {...props} />;

        case PathDifficulty.Medium:
            return <DifficultyMedium {...props} />;

        case PathDifficulty.Hard:
            return <DifficultyHard {...props} />;

        default:
            return <View {...props}></View>;
    }
};

const getDifficultyName = (t: TFunction, difficulty: string, plural: boolean): string => {
    switch (difficulty) {
        case PathDifficulty.VeryEasy:
            return plural ? t('veryEasyPlural') : t('veryEasy');

        case PathDifficulty.Easy:
            return plural ? t('easyPlural') : t('easy');

        case PathDifficulty.Medium:
            return plural ? t('mediumPlural') : t('medium');

        case PathDifficulty.Hard:
            return plural ? t('hardPlural') : t('hard');

        default:
            return '';
    }
};

const getGroomedState = (groomedAt: string): GroomedStateReturnType => {
    const now = moment();
    const date = moment(groomedAt);

    const timePassed = moment.duration(now.diff(date));
    const hoursPassed = Math.floor(timePassed.asHours());
    const weekday = date.day();

    if (hoursPassed < FRESHLY_GROOMED_CUTOFF) {
        return {state: GroomedState.Grooming};
    }

    if (date.isSame(now.clone().startOf('day'), 'd')) {
        return {state: GroomedState.GroomedToday, hoursPassed, weekday};
    }

    if (date.isSame(now.clone().subtract(1, 'days').startOf('day'), 'd')) {
        return {state: GroomedState.GroomedYesterday, hoursPassed, weekday};
    }
    return {state: GroomedState.GroomedPastWeek, hoursPassed, weekday};
};

const getStatusText = (t: TFunction, status: string, groomedAt?: string | null): string => {
    switch (status) {
        case PathStatus.Open:
            if (groomedAt) {
                const groomedState = getGroomedState(groomedAt);

                const weekdays = [
                    t('abbreviatedSunday'),
                    t('abbreviatedMonday'),
                    t('abbreviatedTuesday'),
                    t('abbreviatedWednesday'),
                    t('abbreviatedThursday'),
                    t('abbreviatedFriday'),
                    t('abbreviatedSaturday'),
                ];

                switch (groomedState.state) {
                    case GroomedState.Grooming:
                        return t('groomedStatusCurrently');

                    case GroomedState.FreshlyGroomed:
                    case GroomedState.GroomedToday:
                        return t('groomedStatusPassed', {hoursPassed: groomedState.hoursPassed});

                    case GroomedState.GroomedYesterday:
                        return t('groomedStatusYesterday');

                    case GroomedState.GroomedWithinWeek:
                        return t('groomedStatusWeekday', {weekday: weekdays[groomedState.weekday || 0]});

                    default: 
                        return t('open');
                }
            }
            return t('open');

        case PathStatus.Closed:
            return t('closed');
        
        case PathStatus.NotSet:
            return '---';

        default:
            return '';
    }
};

const getStatusColor = (status: string, groomedAt?: string | null): string => {
    switch (status) {
        case PathStatus.Open:
            if (groomedAt) {
            const groomedState = getGroomedState(groomedAt);

            if (
                groomedState.state === GroomedState.Grooming ||
                groomedState.state === GroomedState.FreshlyGroomed ||
                groomedState.state === GroomedState.GroomedToday
            ) {
                return colors.neutralColor400;
            }
            }
            return colors.successColor500;

        case PathStatus.Closed:
            return colors.errorColor500;

        default:
            return colors.graytone5;
    }
};

const getPathCategoryIcon = (pathCategoryId: string, props?: any) => {
    switch (pathCategoryId) {
        case PathCategoryId.CrossCountrySkiing:
            return <PathCrossCountrySkiing {...props}/>;

        case PathCategoryId.Snowmobiling:
            return <PathSnowmobiling {...props}/>;

        case PathCategoryId.MountainBiking:
            return <PathMountainBiking {...props}/>;

        case PathCategoryId.Hiking:
            return <PathHiking {...props}/>;

        default:
            return <View {...props}></View>;
    }
};

const getPoiCategoryIcon = (poiCategoryId: string, props?: any) => {
    switch (poiCategoryId) {
        case PoiCategoryId.Restaurants:
            return <PoiRestaurants {...props}/>;

        case PoiCategoryId.Cafes:
            return <PoiCafes {...props} />;

        case PoiCategoryId.Attractions:
            return <PoiPlaceOfInterests {...props}/>;

        case PoiCategoryId.Viewpoints:
            return <PoiViewpoints {...props}/>;

        case PoiCategoryId.Parkings:
            return <PoiParking {...props}/>;

        case PoiCategoryId.Cabins:
        case PoiCategoryId.RastsHouses:
            return <PoiCabins {...props}/>;
        
        case PoiCategoryId.BusStops:
            return <PoiBusStops {...props}/>;

        case PoiCategoryId.Shelters:
            return <PoiShelters {...props}/>;

        case PoiCategoryId.Shops:
        case PoiCategoryId.ScooterRentals:
        case PoiCategoryId.SportsRentals:
            return <PoiStores {...props}/>;
    
        case PoiCategoryId.BicycleWashes:
        case PoiCategoryId.Vallabodars:
            return <PoiAttractions {...props}/>;
        
        case PoiCategoryId.Toilets:
            return <PoiToilets {...props}/>;
        
        case PoiCategoryId.Warnings:
            return <PoiWarnings {...props}/>;

        default:
            return <View {...props}></View>;
    }
};

const getAoiCategoryIcon = (aoiCategoryId: string, props?: any) => {
    switch (aoiCategoryId) {
        case AoiCategoryId.WarningArea:
            return <AoiWarning {...props}/>;

        case AoiCategoryId.ImportantArea:
            return <AoiImportant {...props}/>;

        case AoiCategoryId.InfoArea:
            return <AoiInfo {...props}/>;

        default:
            return <View {...props}></View>;
    }
};

const getInformationSeverityIcon = (severity: InformationSeverity, props?: any) => {
    switch (severity) {
        case InformationSeverity.Info:
            return <InformationSeverityInfo {...props}/>;

        case InformationSeverity.Important:
            return <InformationSeverityImportant {...props}/>;

        case InformationSeverity.Warning:
            return <InformationSeverityWarning {...props}/>;

        default:
            return <View {...props}></View>;
    }
};

const getInformationSeverityColor = (severity: InformationSeverity): string => {
    switch (severity) {
        case InformationSeverity.Info:
            return '#8BAC98';
        
        case InformationSeverity.Important:
            return '#CE6B00';
        
        case InformationSeverity.Warning:
            return '#9E2A14';

        default:
            return '#8BAC98';
    }
};

const socialMediaIcons: SocialMediaIcons = {
    instagramUrl: <InstagramIcon />,
    facebookUrl: <FacebookIcon />,
    linkedinUrl: <LinkedInIcon />,
    twitterUrl: <TwitterIcon />,
    vimeoUrl: <VimeoIcon />,
    youtubeUrl: <YouTubeIcon />,
    tripadvisorUrl: <TripAdvisorIcon />,
};
export {
    formatLength,
    filterDisplayed,
    getDifficultyIcon,
    getDifficultyName,
    getStatusText,
    getStatusColor,
    getPathCategoryIcon,
    getPoiCategoryIcon,
    socialMediaIcons,
    getAoiCategoryIcon,
    getInformationSeverityIcon,
    getInformationSeverityColor,
};