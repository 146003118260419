import { useState } from "react";
import { TouchableOpacity, View, ViewProps, Platform, ScrollView } from "react-native";
import { withExternalStyle } from "../../utils";
import { useThemedStyleFunction } from "../theme-provider";
import { useTranslation } from "react-i18next";
import { ThemedText } from "../themed-text";
import { navigationHeaderStyle } from "./navigation-header.style";
import { IconClose, ArrowBack, IconSearch, MapIcon } from '../../assets';
import { FeatureType, MapObjectType } from '../../enums';
import { TextInput } from 'react-native-gesture-handler';
import { getSearch } from "../../utils/api/search.utils";
import { SearchResult } from "../search-result";

type NavigationHeaderProps = ViewProps & {
  title: string;
  leftAlign?: boolean;
  inverted?: boolean;
  onBack?: () => void;
  showResultDropdown?: boolean;
  searchInputText?: string;
  baseUrl?: string;
  hideSearchOnblur?: boolean,
  darkSearch?: boolean,
  onSearchResultNavigate?: (type: MapObjectType, id: string) => void,
  selectedType?: FeatureType,
  hideSideBar?: () => void;
};

const NavigationHeader = (props: NavigationHeaderProps) => {
  const [searchResult, setSearchResult] = useState({} as any);
  const [searchInput, setSearchInput] = useState('');
  const [showSearch, setShowSearch] = useState(false);
  const [t, i18n] = useTranslation();
  const currentLanguage = i18n.language;
  const {
    style: styleProp,
    title,
    inverted,
    leftAlign,
    onBack,
    showResultDropdown = false,
    hideSearchOnblur = true,
    darkSearch = false,
    searchInputText,
    onSearchResultNavigate,
    hideSideBar
  } = props;

  const style = useThemedStyleFunction(navigationHeaderStyle);
  const containerStyle = withExternalStyle(style.container, styleProp);
  const titleStyle = { ...style.title, ...(inverted ? { color: style.inverted.color } : {}) };
  const iconStyle = { ...style.icon, ...(inverted ? { color: style.inverted.color } : {}) };

  const onSearch = async (text: string) => {
    const { baseUrl, selectedType } = props;

    try {
      if (!text) {
        setSearchInput(text);
        setSearchResult({});
        return
      }

      setSearchInput(text);

      if (baseUrl) {
        const searchResult = await getSearch(baseUrl, text, selectedType, currentLanguage);
        setSearchResult(searchResult as any);
      }
    } catch (error) {
      console.log(`${error}: serch error`)
      return Promise.reject(error);
    }
  };

  const toggleSearch = () => {
    setShowSearch(!showSearch);
  };

  const clearSearch = (clicked?: boolean) => {
    const hideSearchOnblur = true;
    const shouldHideSearch = hideSearchOnblur && searchInput === '' ? false : true;

    setSearchInput('');
    setSearchResult({});
    setShowSearch(clicked ? false : shouldHideSearch);
  };

  return (
    <View style={containerStyle} pointerEvents='box-none'>
      {Platform.OS !== 'web' && (onBack || !leftAlign) &&
        <View style={style.iconContainer}>
          {onBack &&
            <TouchableOpacity onPress={onBack}>
              <ArrowBack style={iconStyle} />
            </TouchableOpacity>
          }
        </View>
      }
      <ThemedText style={titleStyle} type={'bold'}>
        {title}
      </ThemedText>
      <View style={style.row}>
        {Platform.OS === 'web' && (
          <>
            {hideSideBar && (
              <TouchableOpacity style={{ ...style.iconContainer, ...style.mapIcon }} onPress={hideSideBar}>
                <MapIcon style={style.icon} />
              </TouchableOpacity>
            )}
            {onBack && (
              <TouchableOpacity style={style.iconContainer} onPress={onBack}>
                <IconClose style={style.icon} />
              </TouchableOpacity>
            )}
          </>
        )}
        {Platform.OS !== 'web' && !showSearch && showResultDropdown && (
          <TouchableOpacity onPress={toggleSearch}>
            <IconSearch style={iconStyle} />
          </TouchableOpacity>
        )}
      </View>
      <View
        style={[
          style.searchContainer,
          searchResult.length !== 0 &&
          showResultDropdown &&
          style.searchContainerHasResult,
        ]}>
        {showSearch && (
          <View
            style={[
              style.searchInputContainer,
              darkSearch && style.darkSearchInputContainer,
            ]}>
            <IconSearch
              style={[style.searchInputIcon, style.searchInputIconLeft]}
            />
            <TextInput
              autoFocus={true}
              style={[style.searchInput]}
              multiline={false}
              textAlignVertical="top"
              value={searchInput}
              placeholder={
                searchInputText ??
                (t('Search...') || '') 
              }
              onChangeText={(text: string) => onSearch(text)}
              onBlur={() => {
                if (hideSearchOnblur || searchInput === '') {
                  setShowSearch(false);
                }
              }}
            />
            <TouchableOpacity
              onPress={() => clearSearch()}
              style={[style.searchInputIconRightContainer]}>
              <IconClose
                style={[style.searchInputIcon, style.searchInputIconRight]}
              />
            </TouchableOpacity>
          </View>
        )}
        {showResultDropdown && showSearch && onSearchResultNavigate ? (
          <ScrollView
            style={style.searchResultDropDownContainer}
            scrollEventThrottle={18}
            bounces={false}
            overScrollMode={'always'}>
            <SearchResult
              searchResult={searchResult}
              onClearSearch={clearSearch}
              onSearchResultNavigate={onSearchResultNavigate} />
          </ScrollView>
        ) : null}
      </View>
    </View>
  );
};

export { NavigationHeader };