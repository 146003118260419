import { TouchableOpacity, View, ViewProps } from "react-native"
import { useTranslation } from "react-i18next";
import { FlagSwedish, FlagEnglish, FlagGermany } from "../../assets";
import { ThemedText } from "../themed-text";
import { useThemedStyleFunction } from "../theme-provider";
import { languageSelectorStyle } from "./language-selector.style";
import { withExternalStyle } from "../../utils";
import { useState } from "react";

type LanguageSelectorProps = ViewProps & {
    inverted?: boolean;
    showTitle?: boolean;
};

const SUPPORTED_LANGUAGES = [
    {code: 'en', component: FlagEnglish},
    {code: 'sv', component: FlagSwedish},
    {code: 'de', component:  FlagGermany},
];

const LanguageSelector = (props: LanguageSelectorProps) => {
    const {style: styleProp, inverted, showTitle=true} = props;
    const {t, i18n} = useTranslation();
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

    const style = useThemedStyleFunction(languageSelectorStyle);
    const containerStyle = withExternalStyle(style.container, styleProp);

    return (
        <View style={containerStyle}>
            {showTitle && 
                <ThemedText style={[style.text, inverted && style.textInverted]}>
                    {t('chooseLanguage')}
                </ThemedText>
            }
            <View style={style.row}>
                {SUPPORTED_LANGUAGES.map(({code, component: Icon}) => {
                    const selected = selectedLanguage === code;

                    return (
                        <TouchableOpacity key={code}
                            style={[style.touchable, selected && (inverted ? style.selectedInverted : style.selected)]}
                            onPress={() => {
                                setSelectedLanguage(code);
                                i18n.changeLanguage(code);
                            }}>
                            <Icon style={style.flag}/>
                        </TouchableOpacity>
                    );
                })}
            </View>
        </View>
    )
};

export { LanguageSelector };