import { useCallback, useEffect } from "react";
import { View, ViewProps } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { shallow } from "zustand/shallow";
import {
    PathDetailsContentTop,
    PathDetailsContentBottom,
    PoiDetailsContentTop,
    PoiDetailsContentBottom,
    SnowmobileSubPathDetailsContentTop,
    useThemedStyleFunction,
    withExternalStyle,
    FeatureType,
    PathCategoryId,
    NavigationHeader,
    AoiDetailsContentTop,
    AoiDetailsContentBottom,
    YoutubeWebPlayer,
} from "@miqmap/shared";
import { useDataStore, useImageViewerStore, useStateStore , useVideoViewerStore} from "../../../stores";
import { sideBarContentStyle } from "./side-bar-content.style";
import { getTranslatedProperty } from "../../../localization";
import { PathDirection } from '@miqmap/map-content/src/enums';

type SideBarDetailProps = {
    navigation?: any;
} & ViewProps;

const SideBarDetail = (props: SideBarDetailProps) => {
    const { style: styleProp, navigation } = props;
    
    const { selectedType, selectedId, setSelected, pathDirection } = useStateStore(state => state, shallow);
    const { paths, pathGpsCoordinates, pathCategories, pois, poiCategories, snowmobileSubPaths, aois, aoiCategories } = useDataStore(state => state, shallow);
    const { showImageViewer } = useImageViewerStore(state => state, shallow);
    const { showVideoViewer } = useVideoViewerStore(state => state, shallow);
    const {setPathDirection, setSideBarOpen} = useStateStore(state => state, shallow);

    const hasPathData = paths && pathGpsCoordinates && pathCategories;
    const hasPoiData = pois && poiCategories;
    const hasAoiData = aois && aoiCategories;
    const hasSnowmobileSubPathData = snowmobileSubPaths && pathCategories;

    // Remove current selection if we close this screen
    useEffect(() => {
        const unsubscribe = navigation.addListener('transitionStart', (event: any) => {
            if (event.data.closing) {
                setSelected(undefined, undefined);
            }
        });
        
        return unsubscribe;
    }, [navigation, setSelected]);

    // Get title for feature
    let title = '';
    switch (selectedType) {
        case FeatureType.Path:
            if (selectedId !== undefined && paths) {
                title = getTranslatedProperty('name', paths[selectedId]);
            }
            break;

        case FeatureType.Poi:
            if (selectedId !== undefined && pois) {
                title = getTranslatedProperty('name', pois[selectedId]);
            }
            break;

        case FeatureType.Aoi:
            if (selectedId !== undefined && aois) {
                title = getTranslatedProperty('name', aois[selectedId]);
            }
            break;

        case FeatureType.SnowmobileSubPath:
            if (selectedId !== undefined && snowmobileSubPaths) {
                title = getTranslatedProperty('name', snowmobileSubPaths[selectedId]);
            }
            break;

        default:
            break;
    }

    const handleOnBack = useCallback(() => {
        setSelected();
    }, [setSelected]);

    const handleOnImagePress = useCallback((images: string[], index: number) => {
        showImageViewer(images, index);
    }, [showImageViewer]);
    
    const handleOnVideoPress = useCallback((videoId: string) => {
        showVideoViewer(videoId);
    }, [showVideoViewer]);

    const handlePathDirection = (direction: PathDirection) => {
        setPathDirection(direction);
    };

    const hideSideBar = useCallback(() => setSideBarOpen(false), [setSideBarOpen]);

    const style = useThemedStyleFunction(sideBarContentStyle);
    const containerStyle = withExternalStyle(style.brightContainer, styleProp);

    return (
        <View style={containerStyle}>
            <NavigationHeader title={title} onBack={handleOnBack} hideSideBar={hideSideBar} />
            <ScrollView>
                <View style={style.marginBottom}>
                    { selectedType === FeatureType.Path && selectedId !== undefined && hasPathData &&
                        <View>
                            <PathDetailsContentTop {...paths[selectedId]}
                                gpsCoordinates={pathGpsCoordinates[selectedId]}
                                category={pathCategories[paths[selectedId].pathCategoryId]}/>
                            <PathDetailsContentBottom {...paths[selectedId]}
                                gpsCoordinates={pathGpsCoordinates[selectedId]}
                                closestIndex={-1}
                                onImagePress={handleOnImagePress}
                                pathDirection={pathDirection}
                                onPathDirection={handlePathDirection}
                                onVideoPress={handleOnVideoPress}
                                videoPlayer={YoutubeWebPlayer}/>
                        </View>
                    }
                    { selectedType === FeatureType.Poi && selectedId !== undefined && hasPoiData &&
                        <View>
                            <PoiDetailsContentTop {...pois[selectedId]}
                                category={poiCategories[pois[selectedId].poiCategoryId]}/>
                            <PoiDetailsContentBottom {...pois[selectedId]}
                                onImagePress={handleOnImagePress}
                                onVideoPress={handleOnVideoPress}
                                videoPlayer={YoutubeWebPlayer}/>
                        </View>
                    }
                    { selectedType === FeatureType.Aoi && selectedId !== undefined && hasAoiData &&
                        <View>
                            <AoiDetailsContentTop {...aois[selectedId]}
                                category={aoiCategories[aois[selectedId].aoiCategoryId]}/>
                            <AoiDetailsContentBottom {...aois[selectedId]}
                                onImagePress={handleOnImagePress}
                                onVideoPress={handleOnVideoPress}
                                videoPlayer={YoutubeWebPlayer}/>
                        </View>
                    }
                    { selectedType === FeatureType.SnowmobileSubPath && selectedId !== undefined && hasSnowmobileSubPathData &&
                        <View>
                            <SnowmobileSubPathDetailsContentTop {...snowmobileSubPaths[selectedId]}
                                category={pathCategories[PathCategoryId.Snowmobiling]}/>
                        </View>
                    }
                </View>
            </ScrollView>
        </View>
    );
};

export { SideBarDetail };