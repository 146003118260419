import i18n from 'i18next';

const DEFAULT_LANGUAGE = 'en';

const getTranslatedProperty = (key: string, object: Record<any, any>) => {
    if (!key || !object || Object.keys(object).length === 0) return '';

    const iso = i18n.language ?? '';
    return object[key]?.[iso] ?? object[key]?.[DEFAULT_LANGUAGE] ?? ''
};

export { getTranslatedProperty, DEFAULT_LANGUAGE };