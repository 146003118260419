export enum PathType {
    CrossCountrySkiing = 'CATEGORY_1',
    Snowmobiling = 'CATEGORY_2', // NOTE: This should not be used anymore.
    MountainBiking = 'CATEGORY_3',
    Hiking = 'CATEGORY_4',
};
  
export enum PathStatus {
    Open = 'open',
    Closed = 'closed',
    NotSet = 'notSet',
};
  
export enum PathDifficulty {
    VeryEasy = 'veryEasy',
    Easy = 'easy',
    Medium = 'medium',
    Hard = 'hard',
};
  