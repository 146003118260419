
import { TouchableOpacity, ViewProps } from 'react-native'
import { MapObjectType } from '../../enums';
import { getTranslatedProperty } from '../../utils/localization.utils';
import { PathResponse, PoiResponse, AoiResponse } from '../../interfaces';
import { PathListContent, PoiListContent, AoiListContent } from './list-content';
import { SearchResponse } from '../../interfaces/backend/search.interface';
import { StyleSheet } from "react-native";
import { Theme, useThemedStyleFunction } from "../theme-provider";

type SearchResultProps = ViewProps & {
  searchResult?: SearchResponse;
  onClearSearch: (bool: boolean) => void;
  onSearchResultNavigate: (selectedType: MapObjectType, selectedId: string) => void;
};

const SearchResult = ({ searchResult, onClearSearch, onSearchResultNavigate }: SearchResultProps) => {
  let globalIndex = -1;
  const resultComponents = [];
  const style = useThemedStyleFunction(styles)

  const handlePressAndNavigate = (selectedType: MapObjectType, selectedId: number) => {
    onClearSearch(true);
    onSearchResultNavigate(selectedType, selectedId.toString())
  };

  // Function to render list content with TouchableOpacity
  const renderTouchableContent = (content: JSX.Element, onPress: () => void) => {
    globalIndex += 1;
    return (
      <TouchableOpacity key={globalIndex} onPress={onPress} style={style.touchable} >
        {content}
      </TouchableOpacity>
    );
  };

  // Render POI components
  const poiComponents = searchResult?.pois?.map((poi:PoiResponse) => {
    const handlePress = () => {
      handlePressAndNavigate(MapObjectType.Poi, poi.id);
    };
    return renderTouchableContent(
      <PoiListContent
        name={getTranslatedProperty('name', poi)}
        category={poi?.pointOfInterestCategory}
      />,
      handlePress
    );
  });
  resultComponents.push(poiComponents);

  // Render AOI components
  const aoiComponents = searchResult?.aois?.map((aoi: AoiResponse) => {
    const handlePress = () => {
      handlePressAndNavigate(MapObjectType.Aoi, aoi.id);
    };
    return renderTouchableContent(
      <AoiListContent
        name={getTranslatedProperty('name', aoi)}
        category={aoi?.areaOfInterestCategory}
      />,
      handlePress
    );
  });
  resultComponents.push(aoiComponents);

  // Render Path components
  const pathComponents = searchResult?.paths?.map((path: PathResponse) => {
    const handlePress = () => {
      handlePressAndNavigate(MapObjectType.Path, path.id);
    };
    return renderTouchableContent(
      <PathListContent
        length={path.length}
        tags={path.tags}
        status={path.status}
        difficulty={path.difficulty}
        groomedAt={path.groomedAt}
        name={getTranslatedProperty('name', path)}
      />,
      handlePress
    );
  });
  resultComponents.push(pathComponents);

  return <>{resultComponents}</>;
}

const styles = (theme: Theme) => StyleSheet.create({
  touchable: {
    borderBottomWidth: 1,
    borderBottomColor: theme.color.graphBackground,
  },
});

export { SearchResult }