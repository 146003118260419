import { PathGpsCoordinate } from '../interfaces';

const RAD_TO_DEG = Math.PI / 180;
const EARTH_DIAMETER = 12742;

/**
 * Get the lowest elevation point
 */
const getMinElevation = (gpsCoords: PathGpsCoordinate[]): number => {
    const min = gpsCoords.reduce((min, coord) => {
        return coord.elevation && coord.elevation < min ? coord.elevation : min;
    }, 7000);
    return Math.round(min);
};

/**
 * Get the highest elevation point
 */
const getMaxElevation = (gpsCoords: PathGpsCoordinate[]): number => {
    const min = gpsCoords.reduce((max, coord) => {
        return coord.elevation && coord.elevation > max ? coord.elevation : max;
    }, 0);
    return Math.round(min);
};

/**
 * Get the elevation between two points with one decimal precision
 */
const getElevationSpan = (min: number, max: number): number => {
    return Math.round(((max - min) * 10) / 10);
};

/**
 * Get the distance between two geographic coordinates
 */
const getGeoDistance = (
    lon1: number,
    lat1: number,
    lon2: number,
    lat2: number,
): number => {
    const a = 0.5 -
        Math.cos((lat2 - lat1) * RAD_TO_DEG) / 2 +
        (Math.cos(lat1 * RAD_TO_DEG) *
        Math.cos(lat2 * RAD_TO_DEG) *
        (1 - Math.cos((lon2 - lon1) * RAD_TO_DEG))) /
        2;

    return EARTH_DIAMETER * Math.asin(Math.sqrt(a));
};

/**
 * Get the distance from coordinate at index 0 to a specified coordinate index
 */
const getDistanceTo = (gpsCoords: PathGpsCoordinate[], index: number): number => {
    let distance = 0;

    for (let i = 1; i < index; i++) {
        const {longitude: lon2, latitude: lat2} = gpsCoords[i];
        const {longitude: lon1, latitude: lat1} = gpsCoords[i - 1];

        distance += getGeoDistance(lon1, lat1, lon2, lat2);
    }

    return distance;
};

export {
    getMinElevation,
    getMaxElevation,
    getElevationSpan,
    getDistanceTo,
};
