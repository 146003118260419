export default {
    // Status
    open: 'Offen',
    closed: 'Geschlossen',
    groomedStatusCurrently: 'Körperpflege',
    groomedStatusPassed: 'Gepflegt ({{hoursPassed}} uhr)',
    groomedStatusYesterday: 'Gestern gepflegt',
    groomedStatusWeekday: 'Gepflegt {{weekday}}',
    // Zone
    low: 'Niedrige Zone',
    high: 'Hohe Zone',
    // Difficulty
    veryEasy: 'Sehr leicht',
    veryEasyPlural: 'Sehr leicht',
    easy: 'Einfach',
    easyPlural: 'Einfach',
    medium: 'Mittel',
    mediumPlural: 'Mittel',
    hard: 'Hart',
    hardPlural: 'Hart',
    // Abbreviated weekdays
    abbreviatedMonday: 'mo',
    abbreviatedTuesday: 'di',
    abbreviatedWednesday: 'mi',
    abbreviatedThursday: 'do',
    abbreviatedFriday: 'fr',
    abbreviatedSaturday: 'sa',
    abbreviatedSunday: 'so',
    // Measurements
    elevationAboveSeaLevel: 'Höhe ü. NN',
    // Misc
    switchDirection: 'Richtung wechseln',
    destinationNamePlaceholder: 'Titlis',
};
